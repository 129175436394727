import { IMAGE_ALPHABET } from "../../../../../svg/image";
import {
  ICardTypeDescription,
  ISelectedDescription,
} from "../../../../../types";

const CardDescription = ({
  descriptions,
}: {
  descriptions: ISelectedDescription;
}) => {
  return (
    <>
      {(descriptions as ICardTypeDescription[]).map(
        ({ title, content }, index) => (
          <div className="card-type-description" key={index}>
            <img
              src={IMAGE_ALPHABET[title.charAt(0).toUpperCase()]}
              alt={title}
            />
            <div className="card-type-title">{title}</div>
            <div className="card-type-content">{content}</div>
          </div>
        )
      )}
    </>
  );
};

export default CardDescription;
