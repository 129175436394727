import { useContext } from "react";
import Logo from "../svg/logo_new.svg";
import { AppContext } from "./App";

const AppHeader = () => {
  const { headerColors, setRefreshCards } = useContext(AppContext);
  const { beta, bookASlot } = headerColors;
  const handleBookSlotClick = () => {
    // window.Calendly.initPopupWidget({
    //   url: "https://calendly.com/bottlenek/30min",
    // });
    window.open("https://calendly.com/bottlenek/30min");
    return false;
  };

  const goTohome = () => {
    setRefreshCards(true);
  };

  return (
    <div className="app-header">
      <div className="logo-text">
        <img
          src={Logo}
          alt="BottleNek Logo"
          onClick={goTohome}
          className="logo-img"
        />
        <div className="beta-text" style={{ background: beta }}>
          BETA
        </div>
      </div>
      <div
        className="bookASlot"
        onClick={handleBookSlotClick}
        style={{ color: bookASlot }}
      >
        Book a Slot
      </div>
    </div>
  );
};

export default AppHeader;
