import C from "./C.png";
import D from "./D.png";
import P from "./P.png";
import S from "./S.png";
import T from "./T.png";

export const IMAGE_ALPHABET: { [key: string]: any } = {
  C,
  D,
  P,
  S,
  T,
};
