import { useContext } from "react";
import IconSwicther from "./icon-switcher";
import { AppContext } from "./App";
import { DECK_VIEW } from "../constants";

const AppFooter = () => {
  const { typeOfView, setTypeOfView, selectedCardNo, setViewChanging } =
    useContext(AppContext);

  const handleViewChange = (switchid: string) => {
    if (typeOfView === DECK_VIEW) {
      setViewChanging?.(true);
      setTimeout(() => {
        setTypeOfView?.(switchid);
        setViewChanging?.(false);
      }, 500);
    } else {
      setTypeOfView?.(switchid);
      setViewChanging?.(true);
      setTimeout(() => {
        setViewChanging?.(false);
      }, 650);
    }
  };

  return (
    <footer
      className={`app-footer ${selectedCardNo !== -1 ? "hideSwitch" : ""}`}
    >
      <IconSwicther
        selectedSwitch={typeOfView}
        setSelectedSwitch={handleViewChange}
      />
    </footer>
  );
};

export default AppFooter;
