import { GIFS } from "../../svg";
import { ICard } from "../../types";

const CardContent = ({ tag, shortDescription, gifFile, color }: ICard) => {
  return (
    <>
      <div className="front-face">
        <label className="card-title">{tag}</label>
        <div className="card-description">
          <article>{shortDescription}</article>
        </div>
        <div className="cardImage">
          <img src={GIFS[gifFile]} alt="BottleNek Logo" />
        </div>
      </div>
      <div className="back-face"></div>
    </>
  );
};

export default CardContent;
