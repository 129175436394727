import { DECK_VIEW, LIST_VIEW } from "../constants";
import { TSide } from "../types";

const getClassName = (
  index: number,
  selectedCardNo: number,
  side: TSide,
  selectedIndex: number,
  secondIndex: number,
  thirdIndex: number,
  swipeOutCard: number,
  lastCardSwip: number,
  isViewChanging: boolean
) => {
  let className = "";
  if (index === selectedCardNo) {
    className = " selectedCard";
  } else if (swipeOutCard === index) {
    className = " swipeOutCard";
  } else if (lastCardSwip === index) {
    className = "lastCardSwip";
  } else if (selectedIndex === index) {
    className = `activeCard selectingCard_${side}`;
  } else if (secondIndex === index) {
    className = "secondCard";
  } else if (thirdIndex === index) {
    className = "thirdCard";
  }

  return `${className} ${selectedCardNo !== -1 ? "close-deck-view" : ""} ${
    isViewChanging ? "changingView" : ""
  }`;
};

const getListClassName = (
  index: number,
  selectedCardNo: number,
  side: TSide,
  selectedIndex: number,
  secondIndex: number,
  thirdIndex: number,
  swipeOutCard: number,
  lastCardSwip: number,
  isViewChanging: boolean
) => {
  let className = "";
  if (index === selectedCardNo) {
    className = " selectedCard";
  } else if (selectedIndex === index) {
    className = `activeCard`;
  } else if (secondIndex === index) {
    className = "secondCard";
  } else if (thirdIndex === index) {
    className = "thirdCard";
  }

  return `${className} ${selectedCardNo !== -1 ? "close-deck-view" : ""} ${
    isViewChanging ? "changingView" : ""
  }`;
};

export const classNameAsPerView: {
  [key: string]: (
    index: number,
    selectedCardNo: number,
    side: TSide,
    selectedIndex: number,
    secondIndex: number,
    thirdIndex: number,
    swipeOutCard: number,
    lastCardSwip: number,
    isViewChanging: boolean
  ) => void;
} = {
  [DECK_VIEW]: getClassName,
  [LIST_VIEW]: getListClassName,
};

export const getlastCardIndex = (
  swipeOutCard: number,
  addLastCardClass: boolean
) => {
  if (!addLastCardClass) {
    return -1;
  }
  return swipeOutCard;
};

interface IGetActiveCard {
  selectedCardNo: number;
  currentCardNo: number;
  index: number;
}

export const getActiveCard: {
  [key: string]: (data: IGetActiveCard) => boolean;
} = {
  [LIST_VIEW]: ({ selectedCardNo, index }: IGetActiveCard) =>
    selectedCardNo === index,
  [DECK_VIEW]: ({ currentCardNo, index }: IGetActiveCard) =>
    currentCardNo === index,
};
