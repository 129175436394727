import { Dispatch, SetStateAction } from "react";
import {
  animationDetails,
  applicationStyleDetails,
  getCardHeight,
  listViewPositionDetails,
} from ".";
import {
  LIST_VIEW,
  BOTH_SIDE,
  LEFT_SIDE,
  NONE_STR,
  REVERSE_BOTH_END,
  REVERSE_BOTH,
  NEXT_CARD,
  RIGHT_SIDE,
} from "../constants";
import { IGeneralDetails } from "../types";
// const innerWidth = window.innerWidth;

// const windowWidth = innerWidth / 2;

export interface IUpdatePosition {
  totalCards: number;
  cardsRef: React.RefObject<HTMLDivElement>;
  generalDetails: React.RefObject<IGeneralDetails>;
  x3: number;
  drawingDirection: string;
  currentCardNo: number;
  selectedCardNo: number;
  setSelectedCardNo: Dispatch<SetStateAction<number>> | undefined;
  handleCurrentCardChange: () => void;
  setCssVaribales: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  typeOfView: string;
  handleCloseSelected: () => void;
}

const noneDrawingDirection = ({ cardsRef }: IUpdatePosition) => {
  const current = cardsRef.current;
  if (current) {
    current.style.transform = "";
  }
};

const reverseBothEnd = ({
  cardsRef,
  generalDetails,
  // setSelectedCardNo,
  handleCloseSelected,
}: IUpdatePosition) => {
  const current = cardsRef.current;
  if (current) {
    current.style.transform = "";
  }
  // if (generalDetails.current) {
  //   generalDetails.current.fireNextEvent = true;
  //   setSelectedCardNo?.(-1);
  // }
  handleCloseSelected();
};

const listViewReverseBothDirection = ({
  cardsRef,
  x3,
  selectedCardNo,
}: IUpdatePosition) => {
  const current = cardsRef.current;
  if (current) {
    const { animationStyle } = listViewPositionDetails(selectedCardNo);
    const { leftWithoutPx, leftDiff, topDiff, topWithoutPx } = animationStyle;
    const { list } = animationDetails();
    const { listCard, selectedCardStyle, browserWidth } =
      applicationStyleDetails();
    let { width, height } = listCard;
    const {
      animationDiff,
      cardWidth: selectedCardWidth,
      left,
    } = selectedCardStyle;
    const windowWidth = browserWidth / 2;
    const { selectedTop } = list;
    const quterWidth = windowWidth / 2;
    let scale = 1,
      rotateY = 0.5 - (x3 * 0.5) / windowWidth,
      translateZ = 200 - (x3 * 200) / windowWidth;
    if (rotateY > 0.25) {
      width = selectedCardWidth - (x3 * animationDiff) / quterWidth;

      const cardStyles = getCardHeight(browserWidth, width, false);
      height = cardStyles.height;
      width = cardStyles.width;
    }

    let calcuatedLeft = left + (x3 * leftDiff) / quterWidth;
    calcuatedLeft = Math.min(calcuatedLeft, leftWithoutPx);

    let calcuatedTop = selectedTop + (x3 * topDiff) / quterWidth;
    calcuatedTop = Math.min(calcuatedTop, topWithoutPx);

    scale = Math.max(0.8, scale);
    rotateY = rotateY < 0 ? 0 : rotateY;
    translateZ = translateZ < 0 ? 0 : translateZ;

    current.style.width = `${width}px`;
    current.style.top = `${calcuatedTop}px`;
    current.style.left = `${calcuatedLeft}px`;
    current.style.height = `${height}px`;

    current.style.transform = `perspective(${
      windowWidth * 2 - 300
    }px) translateZ(${translateZ}px) rotateY(${rotateY}turn) scale(${scale})`;
  }
};

const deckViewReverseBothDirection = ({ cardsRef, x3 }: IUpdatePosition) => {
  const current = cardsRef.current;
  if (current) {
    const { deckCard, selectedCardStyle, browserWidth } =
      applicationStyleDetails();
    let { width, height, left } = deckCard;
    const { card } = animationDetails();
    const { deselectPerspective } = card;
    const { animationDiff, cardWidth: selectedCardWidth } = selectedCardStyle;
    const windowWidth = browserWidth / 2;
    let scale = 1 - (x3 * 0.2) / windowWidth,
      rotateY = 0.5 - (x3 * 0.5) / windowWidth,
      translateZ = 200 - (x3 * 200) / windowWidth;
    if (rotateY > 0.25) {
      width = selectedCardWidth - (x3 * animationDiff) / (windowWidth / 2);
      const cardStyle = getCardHeight(browserWidth, width, false);
      width = cardStyle.width;
      height = cardStyle.height;
      left = cardStyle.left;
    }

    scale = Math.max(0.8, scale);
    rotateY = rotateY < 0 ? 0 : rotateY;
    translateZ = translateZ < 0 ? 0 : translateZ;

    current.style.width = `${width}px`;
    current.style.left = `${left}px`;
    current.style.height = `${height}px`;

    current.style.transform = `perspective(${deselectPerspective}px) translateZ(${translateZ}px) rotateY(${rotateY}turn) scale(${scale})`;
  }
};

const reverseBothDirection = (data: IUpdatePosition) => {
  const { typeOfView } = data;
  return typeOfView === LIST_VIEW
    ? listViewReverseBothDirection(data)
    : deckViewReverseBothDirection(data);
};

const nextCardDirection = ({
  cardsRef,
  generalDetails,
  handleCurrentCardChange,
  totalCards,
}: IUpdatePosition) => {
  const current = cardsRef.current;
  const detailsRef = generalDetails.current;
  if (current && detailsRef) {
    current.style.transform = "";
    current.classList.add("lastCardSwip");

    detailsRef.fireNextEvent = true;
    detailsRef.lastCardSwipe = detailsRef.swipeOutCard;
    detailsRef.swipeOutCard = -1;
    detailsRef.addLastCardClass = true;
  }
  handleCurrentCardChange();
};

const bothAndOnesideDirection = ({
  cardsRef,
  generalDetails,
  x3,
  drawingDirection,
  setCssVaribales,
}: IUpdatePosition) => {
  const current = cardsRef.current;
  const detailsRef = generalDetails.current;
  if (current && detailsRef) {
    const { arcWidth, browserWidth } = applicationStyleDetails();
    const absX3 = Math.abs(x3);
    const multiplier = x3 < 0 ? -1 : 1;
    let rotateY = x3 * multiplier;
    const translateX = x3;
    let rotateZ = Math.floor(x3 * 0.03);
    let scaleValue = "";
    let perspective = `perspective(${300}px)`;

    if (drawingDirection === BOTH_SIDE) {
      let scaleX = absX3 * 0.0011;
      let scaleY = absX3 * 0.0004;
      scaleX = Math.min(scaleX, 0.3);
      scaleY = Math.min(scaleY, 0.1);
      scaleValue = `scale3d(${1 - scaleX}, ${1 - scaleY}, 0.8)`;
      if (absX3 === arcWidth) {
        const scale = 1 + (absX3 * 0.1) / arcWidth;
        scaleX = scale;
        scaleValue = `scale(${scale})`;
      }

      perspective = `perspective(${browserWidth / 2}px)`;
      rotateY = rotateY * 0.1 * -1;
      rotateY = Math.max(rotateY, -95);
      rotateZ = -2;
    } else {
      const scale = 1 + (absX3 * 0.1) / arcWidth;
      scaleValue = `scale(${scale})`;

      rotateZ = (absX3 * 2) / arcWidth;
      rotateZ = Math.ceil(rotateZ * (drawingDirection === LEFT_SIDE ? -1 : 1));
      rotateY = (absX3 * 13) / arcWidth;
      rotateY = Math.ceil(rotateY * (drawingDirection === LEFT_SIDE ? -1 : 1));

      if (absX3 === generalDetails.current.selectingCardPosition) {
        setCssVaribales((state) => ({
          ...state,
          "--selecting-card-position": `${x3}px`,
        }));
      }
    }
    current.style.transform = `${perspective} translateX(${translateX}px) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) ${scaleValue}`;
  }
};

export const updatePosition: {
  [key: string]: (data: IUpdatePosition) => void;
} = {
  [NONE_STR]: noneDrawingDirection,
  [REVERSE_BOTH_END]: reverseBothEnd,
  [REVERSE_BOTH]: reverseBothDirection,
  [NEXT_CARD]: nextCardDirection,
  [BOTH_SIDE]: bothAndOnesideDirection,
  [LEFT_SIDE]: bothAndOnesideDirection,
  [RIGHT_SIDE]: bothAndOnesideDirection,
};
