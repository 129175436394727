import { forwardRef } from "react";
import CardContent from "./content";
import { ICardComponent } from "../../types";

const Card = forwardRef(
  (
    { card, className, style, onClick, index, typeOfView }: ICardComponent,
    cardRef
  ) => {
    const handleMouseEnter = (event: any) => {
      event.preventDefault();
      event.target.classList.add("hover-card");
    };

    const handleMouseLeave = (event: any) => {
      event.preventDefault();
    };

    const onClickHandler = (event: any) => {
      event.stopPropagation();
      onClick(index);
    };

    return (
      <div
        className={`card ${className}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClickHandler}
        style={style}
        //@ts-ignore
        ref={cardRef}
      >
        <CardContent {...card} />
      </div>
    );
  }
);

export default Card;
