import { useEffect } from "react";
import { ISelectedCardClose } from "../../../types";

const SelectedCardClose = ({
  color,
  onCloseSelectedCard,
}: ISelectedCardClose) => {
  const { closeIcon } = color;
  const { icon, background, text } = closeIcon;

  useEffect(() => {
    const eventListener = (event: any) => {
      if (event.keyCode === 27) {
        onCloseSelectedCard(event);
      }
    };
    window.addEventListener("keyup", eventListener);
    return () => {
      window.removeEventListener("keyup", eventListener);
    };
  });

  return (
    <div className="selected-card-close" onClick={onCloseSelectedCard}>
      <svg width="62" height="62" viewBox="0 0 62 62" fill="none">
        <rect width="62" height="62" rx="31" fill={background} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4394 22.4393C23.0251 21.8536 23.9749 21.8536 24.5607 22.4393L31.5 29.3787L38.4393 22.4393C39.0251 21.8536 39.9749 21.8536 40.5607 22.4393C41.1464 23.0251 41.1464 23.9749 40.5607 24.5607L33.6213 31.5L40.5607 38.4393C41.1464 39.0251 41.1464 39.9749 40.5607 40.5607C39.9749 41.1464 39.0251 41.1464 38.4393 40.5607L31.5 33.6213L24.5607 40.5607C23.9749 41.1464 23.0251 41.1464 22.4393 40.5607C21.8536 39.9749 21.8536 39.0251 22.4393 38.4393L29.3787 31.5L22.4394 24.5607C21.8536 23.9749 21.8536 23.0251 22.4394 22.4393Z"
          fill={icon}
        />
      </svg>
      <span style={{ color: text }} className="press-esc-close">
        Press esc
      </span>
    </div>
  );
};

export default SelectedCardClose;
