import { ISelectedCardContent } from "../../../types";
import { GIFS } from "../../../svg";
import Description from "./description";
import { useEffect, useRef, useState } from "react";

const SelectedCardContent = ({
  tag,
  selectedCardDetails,
  gifFile,
}: ISelectedCardContent) => {
  const { title, contents, needContentTabs = [] } = selectedCardDetails;
  const [selectedTab, setSelectedTab] = useState("");
  const contentref = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const controlRef = useRef<{
    scrollEnabled: boolean;
    resetScrollEnabled?: NodeJS.Timeout;
  }>({
    scrollEnabled: true,
  });

  const [scrollCSSVariables, setScrollCSSvariables] = useState({});

  useEffect(
    () =>
      setScrollCSSvariables({
        "--scroll-mask-top-height": "0px",
        "--scroll-mask-bottom-height": "100px",
      }),
    []
  );

  const [contentPostion, setContentPostion] = useState<number[][]>([]);

  useEffect(() => {
    setSelectedTab(needContentTabs[0] || "");
  }, []);

  const scrollComparison = (screenPosition: number[], scrollTop: number) =>
    screenPosition[0] <= scrollTop && scrollTop < screenPosition[1];

  const getPositions = (ref: HTMLDivElement) => {
    const childCount = ref?.childElementCount || 0;
    const poistions = [];
    const childrens = ref.children;
    let value = 1;
    let startPostion = 0;
    while (value < childCount - 1) {
      const { height } = childrens[value].getBoundingClientRect();
      const endPosition = startPostion + height - 20;
      poistions.push([startPostion, endPosition]);
      startPostion = endPosition;
      value++;
    }
    setContentPostion(poistions);
    return poistions;
  };

  const handleScroll = (event: any) => {
    const ref = contentref.current;
    let poistions = [...contentPostion];
    if (ref && poistions.length === 0) {
      poistions = getPositions(ref);
    }
    const childCount = poistions.length;
    if (controlRef.current.scrollEnabled) {
      let itemCount = 0;
      let breakLoop = false;
      const { scrollTop } = event.target;
      if (scrollTop > 30) {
        setScrollCSSvariables((state) => ({
          ...state,
          "--scroll-mask-top-height": "100px",
          "--scroll-top": `${scrollTop} px`,
        }));
      } else {
        setScrollCSSvariables((state) => ({
          ...state,
          "--scroll-mask-top-height": "0px",
          "--scroll-top": "0px",
        }));
      }
      while (!breakLoop && itemCount < childCount) {
        if (scrollComparison(poistions[itemCount], scrollTop)) {
          setSelectedTab(needContentTabs[itemCount]);
          breakLoop = true;
        }
        itemCount++;
      }
    }
  };

  const handleTabClick = (event: any) => {
    const id = event.target.dataset.id;
    controlRef.current.scrollEnabled = false;
    controlRef.current.resetScrollEnabled = setTimeout(() => {
      controlRef.current.scrollEnabled = true;
      clearTimeout(controlRef.current.resetScrollEnabled);
    }, 1000);
    if (contentref.current) {
      contentref.current.children[id].scrollIntoView();
    }
    setSelectedTab(needContentTabs[id - 1]);
  };

  return (
    <div
      className="selected-card-content"
      ref={scrollRef}
      style={scrollCSSVariables}
    >
      <div className="front-face">
        <div className="selected-card-header">
          <label className="card-title">{tag}</label>
        </div>

        <div className="selected-card-title-and-image">
          <div className="selected-card-title">
            {title}
            <div
              className={`tabs ${
                needContentTabs.length !== 0 ? "tabs-margin-bottom" : ""
              }`}
            >
              {needContentTabs.map((tab, index) => (
                <div
                  key={tab}
                  className={`tab ${selectedTab === tab ? "selected-tab" : ""}`}
                  onClick={handleTabClick}
                  data-id={index + 1}
                >
                  {tab}
                </div>
              ))}
            </div>
          </div>
          <div className="selected-card-image">
            <img src={GIFS[gifFile]} alt="BottleNek Logo" />
          </div>
        </div>
        <div className="contents" ref={contentref} onScroll={handleScroll}>
          <div className="top-scroll-mask"></div>
          {contents.map((data, index) => (
            <Description {...data} key={index} />
          ))}
          <div className="bottom-scroll-mask" />
        </div>
      </div>
      <div className="back-face"></div>
    </div>
  );
};

export default SelectedCardContent;
