import { useEffect, useRef } from "react";
import { TSide } from "../../types";
import {
  BOTH_SIDE,
  LEFT_SIDE,
  NEXT_CARD,
  NONE_STR,
  REVERSE_BOTH,
  REVERSE_BOTH_END,
  RIGHT_SIDE,
} from "../../constants";
import { animationDetails, getAnimationStep } from "../../utils";

const arcDraw = (
  ctx: CanvasRenderingContext2D,
  [x1, y1]: number[],
  [x2, y2]: number[],
  [x3, y3]: number[],
  [x4, y4]: number[],
  [x5, y5]: number[],
  nextColor: string
) => {
  if (ctx) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.bezierCurveTo(x2, y2, x3, y3, x4, y4);
    ctx.lineTo(x4, y4);
    ctx.lineTo(x5, y5);
    ctx.lineTo(x1, y1);
    ctx.fillStyle = nextColor;
    ctx.fill("nonzero");
    ctx.closePath();
  }
};

const fullCanvasDraw = (
  ctx: CanvasRenderingContext2D,
  [x1, y1]: number[],
  [x2, y2]: number[],
  [x3, y3]: number[],
  [x4, y4]: number[],
  [x5, y5]: number[],
  [x6, y6]: number[],
  nextColor: string
) => {
  if (ctx) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.bezierCurveTo(x1, y1, x2, y2, x3, y3);
    ctx.lineTo(x4, y4);
    ctx.bezierCurveTo(x4, y4, x5, y5, x6, y6);
    ctx.lineTo(x1, y1);
    ctx.fillStyle = nextColor;
    ctx.fill("nonzero");
    ctx.closePath();
  }
};

interface ICanvas {
  side: TSide;
  backgroundColor: string;
  nextColor: string;
  rippleColor: string;
  arcWidth: number;
  boxWidth: number;
  isSwipeOutCard: boolean;
  updatePosition: (width: number, angleDirection: string) => void;
}

const Canvas = ({
  side,
  backgroundColor,
  nextColor,
  arcWidth,
  updatePosition,
  isSwipeOutCard,
  boxWidth,
  rippleColor,
}: ICanvas) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const timerRef = useRef<{
    animationFrameId: number | null;
    width: number;
    height: number;
    startPosition: number;
    side: TSide;
    canvasColor: string;
    animationStep: number;
    stopAnimationPosition: number;
    halfWidth: number;
    fullWidth: number;
    addOrDelete: number;
    isSwipeOutCard: boolean;
  }>({
    animationFrameId: null,
    startPosition: 0,
    width: 0,
    side: NONE_STR,
    height: 0,
    canvasColor: "#0095FD",
    animationStep: 0,
    stopAnimationPosition: 0,
    halfWidth: 0,
    fullWidth: 0,
    addOrDelete: 0,
    isSwipeOutCard: true,
  });

  useEffect(() => {
    const fullWidth = window.innerWidth;
    const width = fullWidth / 2;
    const height = window.innerHeight;
    timerRef.current = {
      ...timerRef.current,
      width,
      startPosition: width,
      height,
      fullWidth,
      halfWidth: width,
    };
  }, []);

  useEffect(() => {
    clearAnimationFrame();
    side && INITIAL_SETUP[side]();
  }, [side, arcWidth]);

  const clearAnimationFrame = () => {
    const { animationFrameId } = timerRef.current;
    animationFrameId && window.cancelAnimationFrame(animationFrameId);
  };

  const bothSideCanvasSetup = () => {
    const { startPosition, width, halfWidth } = timerRef.current;
    if (startPosition < width) {
      timerRef.current.width = halfWidth - (width - startPosition);
    }
    timerRef.current = {
      ...timerRef.current,
      side,
      canvasColor: isSwipeOutCard ? nextColor : rippleColor,
      isSwipeOutCard,
      animationStep: getAnimationStep(halfWidth, 3),
      stopAnimationPosition: -100,
    };
    timerRef.current.animationFrameId =
      window.requestAnimationFrame(bothSideCanvas);
  };

  const bothSideReverseCanvasSetup = () => {
    timerRef.current.width = 0;
    timerRef.current.side = side;
    timerRef.current.animationFrameId = window.requestAnimationFrame(
      bothSideReverseCanvas
    );
  };

  const oneSideCanvasDrawSetup = () => {
    const endPosition =
      timerRef.current.startPosition + (side === LEFT_SIDE ? -1 : 1) * arcWidth;
    const addOrDelete = timerRef.current.width < endPosition ? 1 : -1;
    timerRef.current = {
      ...timerRef.current,
      side,
      addOrDelete,
      canvasColor: nextColor,
      stopAnimationPosition: endPosition,
    };
    timerRef.current.animationFrameId =
      window.requestAnimationFrame(oneSideCanvas);
  };

  const noneSideCanvasDrawSetup = () => {
    const { side: previousSide } = timerRef.current;
    if (previousSide && [RIGHT_SIDE, LEFT_SIDE].includes(previousSide)) {
      const endPosition = timerRef.current.startPosition;
      const addOrDelete = timerRef.current.width < endPosition ? 1 : -1;
      timerRef.current = {
        ...timerRef.current,
        addOrDelete,
        canvasColor: nextColor,
        stopAnimationPosition: endPosition,
      };
      timerRef.current.animationFrameId =
        window.requestAnimationFrame(oneSideCanvas);
    } else {
      timerRef.current.width = timerRef.current.startPosition;
    }
  };

  const INITIAL_SETUP = {
    [BOTH_SIDE]: bothSideCanvasSetup,
    [REVERSE_BOTH]: bothSideReverseCanvasSetup,
    [LEFT_SIDE]: oneSideCanvasDrawSetup,
    [RIGHT_SIDE]: oneSideCanvasDrawSetup,
    [NONE_STR]: noneSideCanvasDrawSetup,
  };

  const noneSideCanvasDraw = () => {
    timerRef.current.width = timerRef.current.startPosition;
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, 3000, 3000);
    }
    updatePosition(0, NONE_STR);
  };

  const oneSideCanvas = () => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, 3000, 3000);
      const { canvas } = animationDetails();
      const { oneDirection } = canvas;
      const { height, canvasColor, addOrDelete, stopAnimationPosition } =
        timerRef.current;

      const width = timerRef.current.startPosition;
      let drwaingWidth = timerRef.current.width + oneDirection * addOrDelete;
      const curveWidth = width - drwaingWidth;
      const curvePosition = curveWidth * 0.7;

      let x3 = 0;
      let x2 = 0;

      x2 = width - curveWidth;
      x3 = x2 - curvePosition;

      if (
        (addOrDelete === -1 && x2 <= stopAnimationPosition) ||
        (addOrDelete === 1 && x2 >= stopAnimationPosition)
      ) {
        x2 = stopAnimationPosition;
        drwaingWidth = stopAnimationPosition;
      }

      updatePosition(
        Math.floor(x2 - width),
        x2 < width ? LEFT_SIDE : RIGHT_SIDE
      );

      timerRef.current.width = drwaingWidth;

      arcDraw(
        ctx,
        [width, 0],
        [x2, 0],
        [x3, height / 2],
        [x2, height],
        [width, height],
        x2 < width ? canvasColor : rippleColor
      );

      if (x2 !== stopAnimationPosition) {
        timerRef.current.animationFrameId =
          window.requestAnimationFrame(oneSideCanvas);
      } else {
        clearAnimationFrame();
        timerRef.current.side = side;
        if (side === NONE_STR) {
          timerRef.current.animationFrameId =
            window.requestAnimationFrame(noneSideCanvasDraw);
        }
      }
    }
  };

  const bothSideCanvas = () => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, 3000, 3000);
      const { canvas } = animationDetails();
      const { bothDirection } = canvas;
      const {
        startPosition,
        width,
        height,
        canvasColor,
        animationStep,
        stopAnimationPosition,
        isSwipeOutCard,
      } = timerRef.current;
      const curveWidth = startPosition - width;
      const curvePosition = curveWidth * 0.7;
      const x1 = startPosition + curveWidth;
      const x2 = x1 + curvePosition;
      const x3 = startPosition - curveWidth;
      const x4 = x3 - curvePosition;

      fullCanvasDraw(
        ctx,
        [x1, 0],
        [x2, height / 2],
        [x1, height],
        [x3, height],
        [x4, height / 2],
        [x3, 0],
        canvasColor
      );
      const drwaingWidth = width - bothDirection;
      timerRef.current.width = drwaingWidth;
      if (isSwipeOutCard) {
        updatePosition(x3 - startPosition, BOTH_SIDE);
      }

      if (x3 >= stopAnimationPosition) {
        timerRef.current.animationFrameId =
          window.requestAnimationFrame(bothSideCanvas);
      } else {
        // ctx.clearRect(0, 0, 3000, 3000);
        clearAnimationFrame();
        if (isSwipeOutCard) {
          updatePosition(-1 * drwaingWidth, NEXT_CARD);
        }
        timerRef.current.width = timerRef.current.startPosition;
      }
    }
  };

  const bothSideReverseCanvas = () => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      const { canvas } = animationDetails();
      const { bothDirection } = canvas;
      let { width, fullWidth, halfWidth, height, canvasColor } =
        timerRef.current;
      ctx.clearRect(0, 0, 3000, 3000);

      const curveWidth = halfWidth - width;
      const curvePosition = curveWidth * 0.7;
      const y2 = height / 2;

      const x1 = fullWidth - width;
      const x2 = x1 + curvePosition;
      const x5 = width - curvePosition;

      fullCanvasDraw(
        ctx,
        [x1, 0],
        [x2, y2],
        [x1, height],
        [width, height],
        [x5, y2],
        [width, 0],
        canvasColor
      );

      updatePosition(width, REVERSE_BOTH);

      timerRef.current.width = width + bothDirection;

      if (width > halfWidth) {
        width = halfWidth;
      }

      if (width !== halfWidth) {
        timerRef.current.animationFrameId = window.requestAnimationFrame(
          bothSideReverseCanvas
        );
      } else {
        clearAnimationFrame();
        ctx.clearRect(0, 0, 3000, 3000);
        updatePosition(0, REVERSE_BOTH_END);
      }
    }
  };

  const SIDE_FUNCTION = {
    [BOTH_SIDE]: bothSideCanvas,
    [REVERSE_BOTH]: bothSideReverseCanvas,
    [LEFT_SIDE]: oneSideCanvas,
    [RIGHT_SIDE]: oneSideCanvas,
    [NONE_STR]: noneSideCanvasDraw,
  };

  return (
    <canvas
      // key={side}
      style={{ backgroundColor }}
      className="rateCanvas"
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
    ></canvas>
  );
};

export default Canvas;
