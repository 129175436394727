import { useContext, useState } from "react";
import Cards from "./cards";
import { AppContext } from "./App";

const AppSection = () => {
  const { cards } = useContext(AppContext);
  const [cssVaribales, setCssVaribales] = useState({});

  return (
    <section className="app-section" style={cssVaribales}>
      <Cards cards={cards} setCssVaribales={setCssVaribales} />
      {/* <Card card={cards[0]} className={""} /> */}
    </section>
  );
};

export default AppSection;
