import { ReactElement } from "react";
import { ARTICLE_TYPE, LIST_TYPE } from "../../../../../constants";
import {
  ISelectedDescription,
  TSubDescription,
  TSubDescriptionContent,
} from "../../../../../types";

const SimpleDescription = ({
  descriptions,
}: {
  descriptions: ISelectedDescription;
}) => {
  const listTypeRender = (
    index: number,
    contents: TSubDescriptionContent,
    title?: string
  ) => (
    <article key={index + "list"} className="sub-description">
      <div className="sub-description-header">{title}</div>
      <ul className="sub-description-list">
        {(contents as { subtitle: string; subContent: string }[]).map(
          ({ subtitle, subContent }, liIndex) => (
            <li key={liIndex + "li"}>
              <span className="sub-title">{subtitle}: </span>
              <span className="sub-content">{subContent}</span>
            </li>
          )
        )}
      </ul>
    </article>
  );

  const articleTypeRender = (
    index: number,
    content: TSubDescriptionContent,
    title: string | undefined
  ) => (
    <>
      {title && (
        <div className="article-header" key={index + "header"}>
          {title}
        </div>
      )}
      <article key={index + "content"}>{content as string}</article>
    </>
  );

  const renderMapper: {
    [key: string]: (
      index: number,
      content: TSubDescriptionContent,
      title?: string
    ) => ReactElement;
  } = {
    [ARTICLE_TYPE]: articleTypeRender,
    [LIST_TYPE]: listTypeRender,
  };

  return (
    <>
      {(descriptions as TSubDescription[]).map(
        ({ type, title, content }, index) =>
          renderMapper[type](index, content, title)
      )}
    </>
  );
};

export default SimpleDescription;
