import { useRef, useState, useEffect, useContext, useCallback } from "react";
import Card from "./card";
import { ICards, IGeneralDetails, TSide } from "../../types";
import {
  LEFT_SIDE,
  NONE_STR,
  RIGHT_SIDE,
  BOTH_SIDE,
  VIEW_CLASSNAME_MAPPER,
  DECK_VIEW,
  CONTAINER_CLASSNAME_MAPPER,
} from "../../constants";
import {
  applicationStyleDetails,
  getStyleAccordingToListType,
  isMobileDevice,
  listViewPositionOfCards,
  updatePosition,
} from "../../utils";
import Canvas from "../canvas";
import SelectedCardContent from "./selectedcard";
import { AppContext } from "../App";
import {
  classNameAsPerView,
  getActiveCard,
  getlastCardIndex,
} from "../../utils/class_selector";
import SelectedCardClose from "./selectedcard/selected-card-close";

const Cards = ({ cards, setCssVaribales }: ICards) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement>(null);
  const generalDetails = useRef<IGeneralDetails>({
    selectingCardPosition: 0,
    swipeOutCard: -1,
    lastCardSwipe: -1,
    addLastCardClass: false,
    deckCardWidth: 0,
    fireNextEvent: true,
    isAppRefreshing: false,
  });
  const [arcWidth, setArcWidth] = useState(0);
  const {
    selectedCardNo,
    setSelectedCardNo,
    typeOfView,
    isViewChanging,
    setHeaderColor,
    refreshCards,
    setRefreshCards,
  } = useContext(AppContext);
  const [currentCardNo, setCurrentCardNo] = useState(0);
  const [side, setSide] = useState<TSide>(null);
  const { color: nextColorObj } = cards[currentCardNo];
  const totalCards = cards.length;
  const { color } = cards[(currentCardNo - 1 + totalCards) % totalCards];
  const { rippleColor, titleAndBackground: canvasColor } = color;
  const { titleAndBackground: nextColor } = nextColorObj;

  const { swipeOutCard } = generalDetails.current;

  useEffect(() => {
    if (refreshCards) {
      const target = contentRef.current;
      if (target?.classList.contains("selectedCard")) {
        generalDetails.current = {
          ...generalDetails.current,
          isAppRefreshing: true,
        };
        hanldeCloseSelectedCard();
      } else {
        appRefreshing();
      }
    }
  }, [refreshCards]);

  const appRefreshing = () => {
    setCurrentCardNo(0);
    generalDetails.current = {
      selectingCardPosition: 0,
      swipeOutCard: -1,
      lastCardSwipe: -1,
      addLastCardClass: false,
      deckCardWidth: 0,
      fireNextEvent: true,
      isAppRefreshing: false,
    };
    setSide(null);
    setRefreshCards(false);
  };

  useEffect(() => {
    generalDetails.current = {
      ...generalDetails.current,
      addLastCardClass: false,
      swipeOutCard: -1,
    };
    if (isViewChanging) {
      generalDetails.current.fireNextEvent = false;
      clearPreviousSelection();
      setSide(null);
    } else {
      generalDetails.current.fireNextEvent = true;
    }
  }, [isViewChanging]);

  useEffect(() => {
    const { color } = cards[currentCardNo];
    const { beta, bookASlot } = color;
    setHeaderColor({ beta, bookASlot });
  }, [currentCardNo]);

  const updateCardStyle = () => {
    const { deckCard, selectedCardStyle, arcWidth } = applicationStyleDetails();
    const { width, height } = deckCard;
    const { cardWidth: selectedCardWidth, left } = selectedCardStyle;

    setArcWidth(arcWidth);
    generalDetails.current = {
      ...generalDetails.current,
      selectingCardPosition: width,
      deckCardWidth: width,
    };

    setCssVaribales({
      "--translate-x": `-${window.innerWidth / 2}px`,
      "--selecting-card-position": `${width}px`,
      "--deck-card-width": `${width}px`,
      "--deck-card-height": `${height}px`,
      "--selecting-card-width": `${selectedCardWidth}px`,
      "--selecting-card-left": `${left}px`,
    });
  };

  useEffect(() => {
    updateCardStyle();
    window.addEventListener("resize", () => updateCardStyle());
    return window.removeEventListener("resize", () => updateCardStyle());
  }, []);

  const clearPreviousSelection = useCallback(() => {
    const target = cardsRef.current;
    if (target) {
      target.style.transform = "";
      // target.style.width = "";
    }
  }, []);

  const handleSetSelectedCard = (event: any) => {
    if (!generalDetails.current.fireNextEvent) {
      return;
    }
    const isMobile = isMobileDevice();
    const { nativeEvent } = event;
    generalDetails.current = {
      ...generalDetails.current,
      swipeOutCard: currentCardNo,
      addLastCardClass: false,
    };
    if (
      (isMobile && nativeEvent instanceof TouchEvent) ||
      (!isMobile && nativeEvent instanceof MouseEvent)
    ) {
      clearPreviousSelection();

      const current = cardsRef.current;
      if (current) {
        current.className = "card card-deck-view lastCardSwip";
      }

      generalDetails.current.fireNextEvent = false;
      setSide(BOTH_SIDE);
    }
  };

  const callBackGetStyle = useCallback(
    (selectedSwitch: string, index: number) =>
      getStyleAccordingToListType[selectedSwitch](index),
    []
  );

  const handleMouseEnter = (event: any) => {
    if (!generalDetails.current.fireNextEvent) {
      return;
    }
    event.stopPropagation();
    const { dataset } = event.target;
    const { side: datasetID } = dataset;
    if (!isMobileDevice()) {
      if (side !== datasetID) {
        setSide(() => datasetID);
      }
      setCssVaribales((cssVaribales) => ({
        ...cssVaribales,
        [`--selecting-card-position`]: `${
          datasetID === LEFT_SIDE ? "-" : ""
        }350px`,
      }));
    }
  };

  const handleTouchEnter = (event: any) => {
    event.stopPropagation();
    if (!generalDetails.current.fireNextEvent) {
      return;
    }
    const innerWidth = window.innerWidth / 2;
    const clientX = event.touches[0].clientX;
    const datasetID = clientX < innerWidth ? LEFT_SIDE : RIGHT_SIDE;

    if (side !== datasetID) {
      setSide(() => datasetID);
    }

    const width = Math.abs(innerWidth - clientX);
    if (isMobileDevice()) {
      setCssVaribales((cssVaribales) => ({
        ...cssVaribales,
        [`--selecting-card-position`]: `${
          datasetID === LEFT_SIDE ? "-" : ""
        }${width}px`,
      }));
    }
  };

  const handleTouchEnd = (event: any) => {
    event.stopPropagation();
    if (side === LEFT_SIDE) {
      handleSetSelectedCard(event);
    } else if (side === RIGHT_SIDE) {
      handleSetSelectedCardNo(event);
    }
  };

  const selectedCardHandler = (cardNo: number) => {
    setSide(BOTH_SIDE);
    setSelectedCardNo?.(cardNo);
    generalDetails.current.fireNextEvent = false;
    generalDetails.current = {
      ...generalDetails.current,
      swipeOutCard: -1,
      addLastCardClass: false,
    };
    if (contentRef.current) {
      const target = contentRef.current;
      target.classList.remove("deSelectedCard");
      target.classList.add("selectedCard");
    }
  };

  const handleSetSelectedCardNo = useCallback(
    (event: any) => {
      if (!generalDetails.current.fireNextEvent) {
        return;
      }
      event.stopPropagation();
      const isMobile = isMobileDevice();
      const { nativeEvent } = event;
      clearPreviousSelection();
      if (
        (isMobile && nativeEvent instanceof TouchEvent) ||
        (!isMobile && nativeEvent instanceof MouseEvent)
      ) {
        selectedCardHandler(currentCardNo);
      }
    },
    [currentCardNo]
  );

  const onCloseSelectedCard = useCallback((event: any) => {
    event.stopPropagation();
    if (contentRef.current) {
      hanldeCloseSelectedCard();
    }
  }, []);

  const hanldeCloseSelectedCard = () => {
    if (contentRef.current) {
      generalDetails.current.fireNextEvent = false;
      const target = contentRef.current;
      target.classList.remove("selectedCard");
      const currentCard = cardsRef.current;
      currentCard?.classList.remove("selectedCard");
      currentCard?.classList.add("deselectingCard");
      setSide("reverse_both");
    }
  };

  const updatePositionCallback = (x3: number, drawingDirection: string) => {
    updatePosition[drawingDirection]({
      totalCards: cards.length,
      cardsRef,
      generalDetails,
      x3,
      drawingDirection,
      currentCardNo,
      setSelectedCardNo,
      handleCurrentCardChange,
      setCssVaribales,
      typeOfView,
      selectedCardNo,
      handleCloseSelected,
    });
  };

  const handleCloseSelected = () => {
    if (generalDetails.current) {
      const { isAppRefreshing } = generalDetails.current;
      generalDetails.current.fireNextEvent = true;
      setSelectedCardNo?.(-1);
      if (isAppRefreshing) {
        appRefreshing();
        generalDetails.current.isAppRefreshing = false;
      }
    }
  };

  const handleCurrentCardChange = () => {
    setCurrentCardNo((state) => {
      const index = (state + 1) % totalCards;
      return index;
    });
  };

  const handleMouseOut = () => {
    if (!generalDetails.current.fireNextEvent) {
      return;
    }
    setSide(NONE_STR);
  };

  const mouseEvents = isMobileDevice()
    ? {
        onTouchStart: handleTouchEnter,
        onTouchEnd: handleTouchEnd,
        onTouchMoveCapture: handleTouchEnter,
      }
    : {
        onClick: handleTouchEnd,
        onMouseEnter: handleMouseEnter,
        onMouseMove: handleMouseEnter,
        onMouseOut: handleMouseOut,
      };

  const touchEvents = isMobileDevice()
    ? {
        onTouchStart: handleTouchEnter,
        onTouchEnd: handleTouchEnd,
        onTouchMoveCapture: handleTouchEnter,
      }
    : {};

  const handleCardSelected = useCallback((index: number) => {
    if (generalDetails.current.fireNextEvent) {
      const { left, top } = listViewPositionOfCards(index);
      setCssVaribales((state) => ({
        ...state,
        "--animation-left": left,
        "--animation-top": top,
      }));
      selectedCardHandler(index);
    }
  }, []);

  const renderCards = () => {
    const length = cards.length;
    const secondIndex = (currentCardNo + 1) % length;
    const thirdIndex = (currentCardNo + 2) % length;
    let { swipeOutCard, addLastCardClass, lastCardSwipe } =
      generalDetails.current;
    const lastCardNo = getlastCardIndex(lastCardSwipe, addLastCardClass);

    const html = cards.map((content, index) => {
      let className = classNameAsPerView[typeOfView](
        index,
        selectedCardNo,
        side,
        currentCardNo,
        secondIndex,
        thirdIndex,
        swipeOutCard,
        lastCardNo,
        isViewChanging
      );

      return (
        <Card
          ref={
            getActiveCard[typeOfView]({ selectedCardNo, index, currentCardNo })
              ? cardsRef
              : null
          }
          typeOfView={typeOfView}
          card={content}
          key={index}
          index={index}
          style={callBackGetStyle(typeOfView, index)}
          className={`${className}`}
          onClick={handleCardSelected}
        />
      );
    });
    return (
      <div
        className={`cards ${VIEW_CLASSNAME_MAPPER[typeOfView]} ${
          selectedCardNo !== -1 ? "card-selected" : ""
        }`}
        {...touchEvents}
      >
        {html}
      </div>
    );
  };

  return (
    <>
      <div className="cards-deck">
        {renderCards()}
        <Canvas
          isSwipeOutCard={swipeOutCard !== -1}
          boxWidth={generalDetails.current.deckCardWidth}
          side={side}
          backgroundColor={nextColor}
          nextColor={canvasColor}
          arcWidth={arcWidth}
          rippleColor={rippleColor}
          updatePosition={updatePositionCallback}
        />
      </div>
      {typeOfView === DECK_VIEW && (
        <div
          className={`show-me-and-next-card ${
            selectedCardNo !== -1 ? "card-selected" : ""
          } selecting-card-${side}`}
        >
          <div className="next-card" {...mouseEvents} data-side={LEFT_SIDE}>
            Next Card
          </div>

          <div
            className="card-placeholder"
            onClick={handleSetSelectedCardNo}
          ></div>

          <div className="show-me" {...mouseEvents} data-side={RIGHT_SIDE}>
            Show me!
          </div>
        </div>
      )}

      <div
        ref={contentRef}
        className={`flipCardContainer ${CONTAINER_CLASSNAME_MAPPER[typeOfView]}`}
      >
        {selectedCardNo !== -1 && (
          <>
            <SelectedCardClose
              onCloseSelectedCard={onCloseSelectedCard}
              color={nextColorObj}
            />
            <SelectedCardContent
              {...cards[selectedCardNo]}
              onCloseSelectedCard={onCloseSelectedCard}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Cards;
