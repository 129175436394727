import { IAppConfig } from "../types";
export const ARTICLE_TYPE = "article";
export const LIST_TYPE = "list";

export const DECK_VIEW_CARD_WIDTH_MAX = 292;

export const APP_CONFIG: IAppConfig = {
  cards: [
    {
      tag: "Who we are",
      shortDescription:
        "We're a dynamic hub crafting growth-propelling experiences.",
      color: {
        rippleColor: "#ffc544",
        titleAndBackground: "#0173E7",
        beta: "#B4D9FF",
        bookASlot: "#00356B",
        closeIcon: { icon: "#0051A2", background: "#48A2FF", text: "#00356B" },
      },
      gifFile: "WhoWeAre",
      selectedCardDetails: {
        title:
          "Welcome to Bottlenek, where excellence isn't just a promise – it's our standard.",
        needContentTabs: [],
        contents: [
          {
            title: "Blueprint to Brilliance",
            titleStyle: {
              fontFamily: "Fasthand",
              fontSize: "32px",
              fontWeight: "400",
              color: "#B0B0B0",
            },
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "You've arrived at the summit of design and technological excellence. Here, innovation meets precision, and creativity converges with cutting-edge technology to establish a hub of unparalleled excellence.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Our comprehensive approach ensures that every aspect of your project receives the attention it deserves, resulting in extraordinary outcomes. We transcend traditional boundaries. We are not just a service provider; we are your strategic partners in the journey of creation.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Our team, comprised of visionary designers and tech innovators, is committed to setting new standards in every project we undertake. Our mission is to simplify the intricate and make the complex feel effortless. We redefine the narrative of intricate design and technology, making the complex a breeze.",
              },
            ],
          },
        ],
      },
    },
    {
      tag: "What we do",
      shortDescription:
        "Embrace change by shaping it, not just adapting to it.",
      gifFile: "WhatWeDo",
      color: {
        rippleColor: "#0095FD",
        titleAndBackground: "#FFB800",
        beta: "#FFD77F",
        bookASlot: "#D26C0B",
        closeIcon: { icon: "#EF8724", background: "#FFDB6A", text: "#D26C0B" },
      },
      selectedCardDetails: {
        title: "Embrace change by shaping it, not just adapting to it.",
        needContentTabs: [
          "CX/UX Strategy",
          "Design & Development",
          "Branding & Communication",
          "Space Planning",
          "Experiential Art",
          "Events & Exhibition Designs",
        ],
        contents: [
          {
            title: "CX/UX Strategy",
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "Enhance your digital presence and elevate user engagement with our top-tier CX/UX design services. Specializing in creating customized, intuitive, and visually compelling digital experiences, our design agency stands at the forefront of CX/UX innovation. Through strategic use of in-depth user research and insights, we design captivating web and mobile interfaces that not only meet but exceed user expectations.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Our commitment to leveraging the latest technologies and trends ensures that your brand remains ahead in the ever-evolving digital landscape. Our expert team, known for its proven track record across various industries, delivers solutions tailored to your unique business goals. By choosing us, you're not just improving your digital interfaces; you're investing in a strategic partner dedicated to driving growth, improving conversion rates, and building lasting customer loyalty.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Optimize your online presence with our cutting-edge CX/UX design strategies. Let's transform your website or app into a powerful tool for user engagement, setting your brand apart and fostering meaningful connections with your audience. Partner with us for unparalleled expertise in delivering digital experiences that resonate with users and support your business objectives.",
              },
            ],
          },
          {
            title: "Design & Development",
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "Bottlenek stands at the forefront of B2B enterprise solutions, blending innovative design with strategic insights to craft digital experiences tailored to the evolving needs of businesses, particularly within the B2B sector. Our expertise lies in understanding industry-specific challenges and seizing opportunities for growth. Our approach emphasizes rapid market readiness and expansion, utilizing agile methodologies to accelerate development without sacrificing quality. With a commitment to value-driven pricing, we ensure clients receive exceptional value, providing solutions that are not only effective today but also adaptable for the future through the integration of emerging technologies.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Our design strategies are carefully aligned with your business goals to boost user engagement, efficiency, and impact, optimizing every facet of your digital presence. Bottlenek aims to forge lasting partnerships, offering deep expertise in B2B products and design innovation to elevate your brand. Contact us to explore how we can revolutionize your digital strategy and drive your business forward.",
              },
            ],
          },
          {
            title: "Branding & Communication",
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "Bottlenek merges artistry with innovation, forging digital masterpieces that bridge brands to their audiences in the ever-evolving digital landscape. Driven by the ethos of transcending creative boundaries, this agency champions the unique essence of each brand, turning visions into impactful digital narratives. A symphony of passionate designers, strategists, and storytellers, the team at Bottlenek is dedicated to not just meeting, but exceeding the multifaceted needs of their clients, crafting bespoke strategies that resonate on a deeper level with the target audience.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Bottlenek’s repertoire spans from meticulously crafted branding and identity solutions to cutting-edge digital experiences and strategic communication, all designed to elevate a brand’s presence in its market. Their portfolio, a testament to their versatility and commitment to excellence, showcases a wide array of projects that highlight their capability to innovate and adapt to the dynamic needs of businesses across sectors. Bottlenek extends an invitation to brands looking to navigate the complexities of the digital world, offering a partnership that transforms and elevates their digital identity through designs that not only captivate but also drive meaningful engagement and conversion.",
              },
            ],
          },
          {
            title: "Space Planning",
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "Bottlenek's Creativity meets functionality to transform spaces into stunning works of art. Our bespoke interior design services cater to discerning clients who seek elegance, style, and functionality in every detail of their living or working environment.",
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "We believe that every space has the potential to be extraordinary. Our team of talented designers & engineers brings passion, creativity, and expertise to every project, ensuring that each client's unique vision is brought to life.",
              },
              {
                type: LIST_TYPE,
                title: "Our Services:",
                content: [
                  {
                    subtitle: "Residential Design",
                    subContent:
                      "From cozy apartments to luxurious estates, we specialize in creating personalized living spaces that reflect our clients' lifestyles and personalities.",
                  },
                  {
                    subtitle: "Commercial Design",
                    subContent:
                      "Elevate your brand image with our innovative commercial design solutions. We create functional and stylish environments that enhance productivity and leave a lasting impression on clients and employees alike.",
                  },
                  {
                    subtitle: "Hospitality Design",
                    subContent:
                      "Whether it's a boutique hotel, restaurant, or spa, we specialize in designing immersive hospitality experiences that captivate guests and leave a lasting impression.",
                  },
                  {
                    subtitle: "Retail Design",
                    subContent:
                      "Stand out in a competitive market with our tailored retail design solutions. We create inviting and experiential retail environments that engage customers and drive sales.",
                  },
                ],
              },
              {
                type: LIST_TYPE,
                title: "Why Choose Us:",
                content: [
                  {
                    subtitle: "Tailored Solutions",
                    subContent:
                      "We understand that every client is unique, which is why we tailor our design solutions to meet their specific needs, preferences, and budget.",
                  },
                  {
                    subtitle: "Attention to Detail",
                    subContent:
                      "From concept to completion, we pay meticulous attention to every detail, ensuring that every aspect of the design is executed flawlessly.",
                  },
                  {
                    subtitle: "Collaborative Approach",
                    subContent:
                      "We believe in collaboration and work closely with our clients to bring their vision to life. Our transparent communication and client-focused approach ensure a smooth and enjoyable design experience.",
                  },
                  {
                    subtitle: "Quality Craftsmanship",
                    subContent:
                      "We partner with skilled craftsmen and suppliers who share our commitment to quality, ensuring that every element of the design is executed to the highest standards.",
                  },
                ],
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Let's Transform Your Space: Experience the difference that exceptional interior design can make. Contact us today to schedule a consultation and let us help you transform your space into a masterpiece of design and functionality. Welcome to Bottlenek Technologies , where your dream space awaits.",
              },
            ],
          },
          {
            title: "Experiential Art",
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "Step into a world where art comes alive, and every moment is an opportunity to explore, engage, and be inspired. From interactive installations to immersive multimedia experiences, each piece invites you to step into a world of wonder and discovery.",
              },
              {
                type: LIST_TYPE,
                title: "Our Services:",
                content: [
                  {
                    subtitle: "Interactive Installations",
                    subContent:
                      "Engage all your senses with our interactive installations that invite you to touch, move, and even become a part of the artwork.",
                  },
                  {
                    subtitle: "Immersive Multimedia Experiences",
                    subContent:
                      "Immerse yourself in captivating audio-visual experiences that transport you to otherworldly realms and challenge your perception of reality.",
                  },
                  {
                    subtitle: "Fine Art",
                    subContent:
                      "Experience the thrill of conceptual art that covers expression canvas, murals, sculptures, leaving you spellbound and inspired.",
                  },
                ],
              },
              {
                type: ARTICLE_TYPE,
                content:
                  "Discover the magic of experiential art with Bottlenek Technologies. Whether you're a seasoned art lover or just curious to explore something new, we invite you to step inside and experience art in a whole new way. Welcome to a world where imagination knows no bounds and creativity knows no limits.",
              },
            ],
          },
          {
            title: "Events & Exhibition Designs",
            type: "description",
            descriptions: [
              {
                type: ARTICLE_TYPE,
                content:
                  "At Bottlenek, we're masters of crafting exhibition experiences that captivate and immerse, leaving a lasting imprint on your audience's minds. We blend creativity, innovation, and technical prowess to transform your vision into reality, delivering standout designs that command attention in any exhibition setting.",
              },
              {
                type: LIST_TYPE,
                title: "Our Services:",
                content: [
                  {
                    subtitle: "Design Consultation",
                    subContent:
                      "Dive into a collaborative journey where we delve deep into your goals, brand essence, and target audience. Together, we sculpt designs that not only align with your objectives but also weave a magnetic allure for your audience.",
                  },
                  {
                    subtitle: "Concept Development",
                    subContent:
                      "From the spark of an idea to the grand reveal in mesmerizing 3D/Video/AR/VR renderings, we infuse your vision with creativity and precision. Our team thrives on pushing the envelope, crafting concepts that push boundaries and set your exhibition apart.",
                  },
                  {
                    subtitle: "Fabrication & Construction",
                    subContent:
                      "Melding artistry with cutting-edge technology, we craft exhibition stalls that exude quality and finesse. Whether it's a quaint booth or a sprawling installation, every detail is meticulously crafted to perfection.",
                  },
                  {
                    subtitle: "Installation & Dismantling",
                    subContent:
                      "Leave the logistics to us as our seasoned team handles the setup and teardown with seamless efficiency. From inception to conclusion, we handle every aspect, allowing you to focus on captivating your audience.",
                  },
                ],
              },
              {
                type: ARTICLE_TYPE,
                title: "Ready to Elevate Your Exhibition Experience?",
                content:
                  "Unlock the potential of your exhibition with Bottlenek. Reach out today to spark a conversation about your project and receive a personalized quote. Whether it's a trade show, conference, or product launch, trust us to infuse it with creativity and expertise that ensures success.",
              },
            ],
          },
        ],
      },
    },
    // {
    //   tag: "What we do",
    //   shortDescription:
    //     "Embrace change by shaping it, not just adapting to it.",
    //   gifFile: "WhatWeDo",
    //   color: {
    //     rippleColor: "#0095FD",
    //     titleAndBackground: "#FFB800",
    //     closeIcon: { icon: "#EF8724", background: "#FFDB6A", text: "#D26C0B" },
    //   },
    //   selectedCardDetails: {
    //     title: "Embrace change by shaping it, not just adapting to it.",
    //     needContentTabs: [
    //       "Why Choose Our Crew",
    //       "What Sets Us Apart",
    //       "Sail with the Best",
    //     ],
    //     contents: [
    //       {
    //         title: "Why Choose Our Crew",
    //         type: "cardDescription",
    //         descriptions: [
    //           {
    //             type: LIST_TYPE,
    //             title: "Our Services:",
    //             content: [
    //               "Residential Design: From cozy apartments to luxurious estates, we specialize in creating personalized living spaces that reflect our clients' lifestyles and personalities.",
    //               "Commercial Design: Elevate your brand image with our innovative commercial design solutions. We create functional and stylish environments that enhance productivity and leave a lasting impression on clients and employees alike.",
    //               "Hospitality Design: Whether it's a boutique hotel, restaurant, or spa, we specialize in designing immersive hospitality experiences that captivate guests and leave a lasting impression.",
    //               "Retail Design: Stand out in a competitive market with our tailored retail design solutions. We create inviting and experiential retail environments that engage customers and drive sales.",
    //             ],
    //           },
    //           {
    //             title: "Design Mavericks",
    //             content:
    //               "Our team of design mavens isn't just about creating visuals; they are storytellers, crafting narratives through design that resonate with your audience and elevate your brand. ",
    //           },
    //           {
    //             title: "Tech Virtuosos",
    //             content:
    //               "Tech challenges are opportunities for our virtuosos. Armed with the latest technologies and a hunger for innovation, our tech experts turn your ideas into cutting-edge solutions. ",
    //           },
    //           {
    //             title: "Strategic Thinkers",
    //             content:
    //               "We're not just about the aesthetics; we're about strategy. Every design and tech solution we offer is steeped in a strategic approach, ensuring it aligns perfectly with your goals. ",
    //           },
    //         ],
    //       },
    //       {
    //         title: "What Sets Us Apart",
    //         type: "cardDescription",
    //         descriptions: [
    //           {
    //             title: "Passionate Dedication",
    //             content:
    //               "Our crew doesn't just work; they are passionately dedicated to every project. Your success is our success, and we are committed to delivering the best. ",
    //           },
    //           {
    //             title: "Continuous Innovation",
    //             content:
    //               "The world evolves, and so do we. With a crew dedicated to continuous learning and staying ahead of industry trends, we bring you the latest and most innovative solutions. ",
    //           },
    //           {
    //             title: "Collaborative Spirit",
    //             content:
    //               "When you choose us, you're not just hiring a service; you're gaining a collaborative partner. We work closely with you, ensuring your vision is not just met but exceeded. ",
    //           },
    //         ],
    //       },
    //       {
    //         title: "Sail with the Best",
    //         type: "description",
    //         descriptions: [
    //           {
    //             type: ARTICLE_TYPE,
    //             content:
    //               "Begin an transformative voyage where your design and technological requirements aren't merely fulfilled, but surpassed. Partner with Bottlenek's exceptional crew, adept at transforming your concepts into remarkable realities.",
    //           },
    //           {
    //             type: ARTICLE_TYPE,
    //             content:
    //               "Reach out to us today to initiate discussions about your projects, delve into potential collaborations, and allow our expert team to guide your design and tech endeavors towards unparalleled success.",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // },
    // {
    //   tag: "Who we are",
    //   shortDescription:
    //     "We're a dynamic hub crafting growth-propelling experiences test. Card :::::: 3",
    //   gifFile: "Growth",
    //   color: "#f7e37c",
    //   selectedCardDetails: {
    //     title:
    //       "Welcome to Bottlenek, where excellence isn't just a promise – it's our standard. Card :::::: 1",
    //     contents: [
    //       {
    //         title: "",
    //         type: "description",
    //         descriptions: [
    //           "You've arrived at the summit of design and technological excellence. Here, innovation meets precision, and creativity converges with cutting-edge technology to establish a hub of unparalleled excellence.  Our comprehensive approach ensures that every aspect of your project receives the attention it deserves, resulting in extraordinary outcomes.",
    //           "We transcend traditional boundaries. We are not just a service provider; we are your strategic partners in the journey of creation.",
    //         ],
    //       },
    //     ],
    //   },
    // },
    // {
    //   tag: "Who we are",
    //   shortDescription:
    //     "We're a dynamic hub crafting growth-propelling experiences test. Card :::::: 4",
    //   gifFile: "Growth",
    //   color: "#f7e37c",
    //   selectedCardDetails: {
    //     title:
    //       "Welcome to Bottlenek, where excellence isn't just a promise – it's our standard. Card :::::: 1",
    //     contents: [
    //       {
    //         title: "",
    //         type: "description",
    //         descriptions: [
    //           "You've arrived at the summit of design and technological excellence. Here, innovation meets precision, and creativity converges with cutting-edge technology to establish a hub of unparalleled excellence.  Our comprehensive approach ensures that every aspect of your project receives the attention it deserves, resulting in extraordinary outcomes.",
    //           "We transcend traditional boundaries. We are not just a service provider; we are your strategic partners in the journey of creation.",
    //         ],
    //       },
    //     ],
    //   },
    // },
    // {
    //   tag: "Who we are",
    //   shortDescription:
    //     "We're a dynamic hub crafting growth-propelling experiences test. Card :::::: 5",
    //   gifFile: "Growth",
    //   color: "#f7e37c",
    //   selectedCardDetails: {
    //     title:
    //       "Welcome to Bottlenek, where excellence isn't just a promise – it's our standard. Card :::::: 1",
    //     contents: [
    //       {
    //         title: "",
    //         type: "description",
    //         descriptions: [
    //           "You've arrived at the summit of design and technological excellence. Here, innovation meets precision, and creativity converges with cutting-edge technology to establish a hub of unparalleled excellence.  Our comprehensive approach ensures that every aspect of your project receives the attention it deserves, resulting in extraordinary outcomes.",
    //           "We transcend traditional boundaries. We are not just a service provider; we are your strategic partners in the journey of creation.",
    //         ],
    //       },
    //     ],
    //   },
    // },
    // {
    //   tag: "Who we are",
    //   shortDescription:
    //     "We're a dynamic hub crafting growth-propelling experiences test. Card :::::: 6",
    //   gifFile: "Growth",
    //   color: "#f7e37c",
    //   selectedCardDetails: {
    //     title:
    //       "Welcome to Bottlenek, where excellence isn't just a promise – it's our standard. Card :::::: 1",
    //     contents: [
    //       {
    //         title: "",
    //         type: "description",
    //         descriptions: [
    //           "You've arrived at the summit of design and technological excellence. Here, innovation meets precision, and creativity converges with cutting-edge technology to establish a hub of unparalleled excellence.  Our comprehensive approach ensures that every aspect of your project receives the attention it deserves, resulting in extraordinary outcomes.",
    //           "We transcend traditional boundaries. We are not just a service provider; we are your strategic partners in the journey of creation.",
    //         ],
    //       },
    //     ],
    //   },
    // },
  ],
};
