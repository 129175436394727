import {
  APP_CONFIG,
  DECK_VIEW,
  DECK_VIEW_CARD_WIDTH_MAX,
  LIST_VIEW,
} from "../constants";
export * from "./animation";

export const getArcWidthForSide = (event: MouseEvent, side: string) => {
  const windowWidth = window.innerWidth;
  const mousePosition = event.pageX;
  return isMobileDevice()
    ? sideAndCalculationMapper[side](mousePosition, windowWidth)
    : 350;
};

const rightSideCalcuation = (mousePosition: number, windowWidth: number) => {
  const width = mousePosition - windowWidth / 2;
  return Math.min(width, 350);
};

const leftSideCalcuation = (mousePosition: number, windowWidth: number) => {
  const width = windowWidth / 2 - mousePosition;
  return Math.min(width, 350);
};

const sideAndCalculationMapper: {
  [key: string]: (mousePosition: number, windowWidth: number) => number;
} = {
  right: rightSideCalcuation,
  left: leftSideCalcuation,
};

export const formatCssVaribales = (varibales: { [key: string]: string }) => {
  return Object.entries(varibales).reduce(
    (result, [key, value]) => ({ ...result, [`--${key}`]: value }),
    {}
  );
};

export const isMobileDevice = () =>
  navigator.userAgent.toLowerCase().includes("mobi");

export const getAnimationStep = (halfWidth: number, percentage: number) =>
  Math.ceil((halfWidth * percentage) / 100);

export const getCardHeight = (
  browserWidth: number,
  width: number,
  isMaxWidthRequest: boolean
) => {
  if (isMaxWidthRequest) {
    width = Math.min(width, DECK_VIEW_CARD_WIDTH_MAX);
  }

  const left = (browserWidth - width) / 2;
  return { height: width * 1.44, left, width };
};

let listViewPosition: {
  [key: number]: {
    [key: number]: {
      style: {
        width: string;
        top: string;
        left: string;
        height: string;
      };
      animationStyle: {
        topAddDelete: number;
        leftAddDelete: number;
        leftDiff: number;
        leftWithoutPx: number;
        topDiff: number;
        topWithoutPx: number;
      };
    };
  };
} = {};

export const marginLeftMemo: {
  [key: string]: {
    marginLeft: number;
    gap: number;
  };
} = {};

export const listViewPositionDetails = (cardIndex: number) => {
  const innerWidth = window.innerWidth;
  let position = listViewPosition[innerWidth]?.[cardIndex];

  if (position) {
    return position;
  }
  if (!listViewPosition[innerWidth]) {
    listViewPosition[innerWidth] = {};
  }
  const { listCard, cardCount } = applicationStyleDetails();
  const { list } = animationDetails();
  const { selectedLeft, selectedTop } = list;
  const { height, width } = listCard;
  const rowNo = Math.floor(cardIndex / 3);
  const columnNumber = cardIndex % 3;

  let top = 0;
  let left = 0;

  if (cardCount <= 3) {
    top = (window.innerHeight - height) / 2;
    let { marginLeft, gap } = marginLeftMemo[innerWidth] || {};
    if (!marginLeft) {
      gap = 0.02 * innerWidth;
      marginLeft =
        (innerWidth - ((cardCount - 1) * gap + width * cardCount)) / 2;
    }

    left = marginLeft + (columnNumber - 1) * gap + columnNumber * width;
  } else {
    top = 130 + rowNo * height + rowNo * 50;
    left =
      ((18 + columnNumber * 20 + (columnNumber - 1) * 2) * window.innerWidth) /
      100;
  }

  position = {
    style: {
      width: `${width}px`,
      height: `${height}px`,
      top: `${top}px`,
      left: `${left}px`,
    },
    animationStyle: {
      leftDiff: left - selectedLeft,
      topDiff: top - selectedTop,
      leftWithoutPx: left,
      topWithoutPx: top,
      topAddDelete: top < selectedTop ? 1 : -1,
      leftAddDelete: left < selectedLeft ? -1 : 1,
    },
  };
  listViewPosition[innerWidth][cardIndex] = position;

  return position;
};

export const listViewPositionOfCards = (cardIndex: number) =>
  listViewPositionDetails(cardIndex).style;

let deckViewPosition: {
  [key: string]: {
    width: string;
    height: string;
    top: string;
    left: string;
  };
} = {};

export const deckViewPositionOfCards = () => {
  const windowWidth = window.innerWidth;
  if (deckViewPosition[windowWidth]) {
    return deckViewPosition[windowWidth];
  }
  const { deckCard } = applicationStyleDetails();
  const { height, left, width } = deckCard;
  const top = (window.innerHeight - height) / 2;

  deckViewPosition[windowWidth] = {
    width: `${width}px`,
    height: `${height}px`,
    top: `${top}px`,
    left: `${left}px`,
  };
  return deckViewPosition[windowWidth];
};

export const getStyleAccordingToListType: {
  [key: string]: (cardIndex: number) => {};
} = {
  [DECK_VIEW]: deckViewPositionOfCards,
  [LIST_VIEW]: listViewPositionOfCards,
};

// export const getWidth = () => {
//   const innerWidth = window.innerWidth;
//   if (innerWidth > 1024) {
//     return 0.22;
//   } else if (innerWidth <= 1024 && innerWidth > 500) {
//     return 0.35;
//   } else {
//     return 0.7;
//   }
// };

export const getWidth = () => {
  const innerWidth = window.innerWidth;
  if (innerWidth > 500) {
    return 292;
  } else {
    return 0.7 * innerWidth;
  }
};

export const getArcWidth = () => {
  const innerWidth = window.innerWidth;
  if (innerWidth > 1024) {
    return 0.6;
  } else {
    return 0.3;
  }
};

export const getListWidth = () => (window.innerWidth > 500 ? 0.2 : 0.7);

const applicationStyleDetailsMemo: {
  [key: string]: {
    cardCount: number;
    arcWidth: number;
    browserWidth: number;
    selectedCardStyle: {
      animationDiff: number;
      cardWidth: number;
      left: number;
    };
    deckCard: {
      height: number;
      left: number;
      width: number;
    };
    listCard: {
      height: number;
      left: number;
      width: number;
    };
  };
} = {};

export const getSelectedCardStyles = (
  browserWidth: number,
  cardWidth: number
) => {
  const selectedCardWidth = browserWidth >= 1024 ? 1024 : browserWidth;
  return {
    animationDiff: selectedCardWidth - cardWidth,
    cardWidth: selectedCardWidth,
    left: (browserWidth - selectedCardWidth) / 2,
  };
};

export const applicationStyleDetails = () => {
  const browserWidth = window.innerWidth;
  if (!applicationStyleDetailsMemo[browserWidth]) {
    const deckCardWidthPer = getWidth();
    const listCardWidthPer = getListWidth();
    const deckCard = getCardHeight(
      browserWidth,
      // deckCardWidthPer * browserWidth,
      deckCardWidthPer,
      true
    );
    const { width: deckCardWidth } = deckCard;
    const listCard = getCardHeight(
      browserWidth,
      listCardWidthPer * browserWidth,
      true
    );

    applicationStyleDetailsMemo[browserWidth] = {
      cardCount: APP_CONFIG.cards.length,
      arcWidth: Math.floor(deckCardWidth * getArcWidth()),
      selectedCardStyle: getSelectedCardStyles(browserWidth, deckCardWidth),
      deckCard: deckCard,
      listCard: listCard,
      browserWidth,
    };
  }
  return applicationStyleDetailsMemo[browserWidth];
};

const animationDetailsMemo: {
  [key: string]: {
    canvas: {
      bothDirection: number;
      oneDirection: number;
    };
    card: {
      deselectPerspective: number;
    };
    list: {
      selectedLeft: number;
      selectedTop: number;
    };
  };
} = {};

export const animationDetails = () => {
  const browserWidth = window.innerWidth;
  if (!animationDetailsMemo[browserWidth]) {
    let bothDirection = browserWidth * 0.015;
    let oneDirection = Math.floor(browserWidth * 0.22 * 0.6) / 300;
    let deselectPerspective = browserWidth * 0.8;
    const selectedTop = 130;
    const selectedLeft = window.innerWidth * 0.1;

    if (browserWidth < 800) {
      bothDirection = browserWidth * 0.035;
      deselectPerspective = 800;
    }

    oneDirection = Math.max(oneDirection, 10);

    animationDetailsMemo[browserWidth] = {
      canvas: { bothDirection, oneDirection },
      card: { deselectPerspective },
      list: {
        selectedTop,
        selectedLeft,
      },
    };
  }
  return animationDetailsMemo[browserWidth];
};
