export const DECK_VIEW = "deck";
export const LIST_VIEW = "list";
export const LEFT_SIDE = "left";
export const RIGHT_SIDE = "right";
export const BOTH_SIDE = "both";
export const NEXT_CARD = "nextCard";
export const NONE_STR = "none";
export const REVERSE_BOTH = "reverse_both";
export const REVERSE_BOTH_END = "reverse_both_end";

export const VIEW_CLASSNAME_MAPPER: { [key: string]: string } = {
  [DECK_VIEW]: "card-deck-view",
  [LIST_VIEW]: "card-list-view",
};

export const CONTAINER_CLASSNAME_MAPPER: { [key: string]: string } = {
  [DECK_VIEW]: "deck-view-container",
  [LIST_VIEW]: "list-view-container",
};

export * from "./app-config";
