import { createContext, useState } from "react";
import AppFooter from "./app-footer";
import AppHeader from "./app-header";
import AppSection from "./app-section";
import { APP_CONFIG, DECK_VIEW } from "../constants";
import { ICard } from "../types";

export const AppContext = createContext<{
  typeOfView: string;
  setTypeOfView?: React.Dispatch<React.SetStateAction<string>>;
  cards: ICard[];
  selectedCardNo: number;
  setSelectedCardNo?: React.Dispatch<React.SetStateAction<number>>;
  isViewChanging: boolean;
  setViewChanging?: React.Dispatch<React.SetStateAction<boolean>>;
  headerColors: {
    bookASlot: string;
    beta: string;
  };
  setHeaderColor: React.Dispatch<
    React.SetStateAction<{
      bookASlot: string;
      beta: string;
    }>
  >;
  refreshCards: boolean;
  setRefreshCards: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  typeOfView: DECK_VIEW,
  cards: [],
  selectedCardNo: -1,
  isViewChanging: false,
  headerColors: {
    bookASlot: "#ccc",
    beta: "#ccc",
  },
  setHeaderColor: () => "",
  setRefreshCards: () => "",
  refreshCards: false,
});

const App = () => {
  const [selectedCardNo, setSelectedCardNo] = useState(-1);
  const [isViewChanging, setViewChanging] = useState(false);
  const [typeOfView, setTypeOfView] = useState(DECK_VIEW);
  const [refreshCards, setRefreshCards] = useState(false);
  const cards = APP_CONFIG.cards;
  const [headerColors, setHeaderColor] = useState({
    bookASlot: cards[0].color.bookASlot,
    beta: cards[0].color.beta,
  });

  return (
    <AppContext.Provider
      value={{
        refreshCards,
        setRefreshCards,
        isViewChanging,
        setViewChanging,
        typeOfView,
        setTypeOfView,
        cards,
        selectedCardNo,
        setSelectedCardNo,
        headerColors,
        setHeaderColor,
      }}
    >
      <div className="app">
        <AppHeader />
        <AppSection />
        <AppFooter />
      </div>
    </AppContext.Provider>
  );
};

export default App;
