import { ISelectedContents } from "../../../../types";
import { Contents } from "./content";

const Description = ({
  descriptions,
  title,
  type,
  titleStyle = {},
}: ISelectedContents) => {
  const Content = Contents[type];
  return (
    <div
      className={`selected-card-description ${
        title === "" ? "selected-card-notitle" : ""
      }`}
    >
      {title && (
        <div className="selected-card-content-header" style={titleStyle}>
          {title}
        </div>
      )}
      <div className={`selected-card-content-body ${type}`}>
        <Content descriptions={descriptions} />
      </div>
    </div>
  );
};

export default Description;
