import { DECK_VIEW, LIST_VIEW } from "../../constants";
import DeckView from "../../svg/deck_view_icon.svg";
import ListView from "../../svg/list_view_icon.svg";

interface IIconSwicther {
  selectedSwitch: string;
  setSelectedSwitch?: (switchid: string) => void;
}

const IconSwicther = ({ selectedSwitch, setSelectedSwitch }: IIconSwicther) => {
  const style = {
    left:
      selectedSwitch === DECK_VIEW
        ? "calc(0% + (50% - 36px) / 2)"
        : "calc(50% - 1px + (50% - 36px) / 2)",
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    const { dataset } = event.target;
    const { switchid } = dataset;
    setSelectedSwitch?.(switchid);
  };

  return (
    <div className="icons-switch">
      <div>
        <div className="icon-selector" style={style}></div>
        <div
          className="icon-switch"
          data-switchid={DECK_VIEW}
          onClick={handleClick}
        >
          <img src={DeckView} alt="Deck View Logo" data-switchid={DECK_VIEW} />
        </div>
        <div
          className="icon-switch"
          data-switchid={LIST_VIEW}
          onClick={handleClick}
        >
          <img src={ListView} alt="List View Logo" data-switchid={LIST_VIEW} />
        </div>
      </div>
    </div>
  );
};

export default IconSwicther;
