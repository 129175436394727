import { ISelectedDescription } from "../../../../../types";
import CardDescription from "./card-description";
import SimpleDescription from "./simple-description";

export const Contents: {
  [key: string]: ({
    descriptions,
  }: {
    descriptions: ISelectedDescription;
  }) => JSX.Element;
} = {
  description: SimpleDescription,
  cardDescription: CardDescription,
};
